import Vue from "vue";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "./assets/css/style.css";

import "./vee-validate";

import "@/mixins/deviceDetectMixin";
import "@/mixins/metaMixin";

import qs from "qs";
import axios from "axios";

import store from "@/store";

import App from "./App.vue";
import router from "./router";
import i18n from "./plugins/i18n";

import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.prototype.publicPath = process.env.BASE_URL;

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VueMeta);

Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import LayoutEmpty from "@/layouts/LayoutEmpty.vue";

Vue.component("loader", { template: "<div class='loader'></div>" });
Vue.component("layout-default", LayoutDefault);
Vue.component("layout-empty", LayoutEmpty);

Vue.prototype.$eventHub = new Vue();

const app = new Vue({
  router,
  data: { loader: true },
  i18n,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");

router.beforeEach((to, from, next) => {
  app.loader = true;
  next();
});

router.afterEach(() => {
  app.loader = false;
});
