import { localize, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

localize({
  en: {
    messages: {
      required: "This field is required",
      email: "Email address is not valid"
    }
  },
  es: {
    messages: {
      required: "Este campo es requerido",
      email: "Este correo electrónico no es válido"
    }
  }
});

extend("required", required);
extend("email", email);
