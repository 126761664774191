function load(component) {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`);
}

import { SUPPORTED_LOCALES } from "../constants/locale";

let languages = SUPPORTED_LOCALES.map(value => {
  return value["code"];
});

let lang = "us";
let browserLang = navigator.language.split("-")[0];
if (browserLang === "es") {
  lang = "mx";
}

export default [
  {
    path: "/",
    name: "Home",
    component: load("Home"),
    meta: {
      layout: "layout-default"
    },
    beforeEnter: (to, from, next) => {
      if (
        window.sessionStorage !== undefined &&
        !sessionStorage.getItem("lang") &&
        lang !== process.env.VUE_APP_I18N_LOCALE &&
        languages.includes(lang)
      ) {
        sessionStorage.setItem("lang", lang);
        next({ path: lang });
      }

      next();
    }
  },
  {
    path: "free",
    name: "Download",
    component: load("Download"),
    alias: "gratis",
    meta: {
      layout: "layout-default"
    }
  },
  {
    path: "brands",
    name: "Brands",
    component: load("Brands"),
    meta: {
      layout: "layout-default"
    }
  },
  {
    path: "our-story",
    name: "OurStory",
    component: load("OurStory"),
    meta: {
      layout: "layout-default"
    }
  },
  {
    path: "concert",
    name: "concert",
    component: load("Concert"),
    meta: {
      layout: "layout-default"
    }
  },
  {
    path: "privacy",
    name: "Privacy",
    component: load("Privacy"),
    meta: {
      layout: "layout-default"
    }
  },
  {
    path: "terms",
    name: "Terms",
    component: load("Terms"),
    meta: {
      layout: "layout-default"
    }
  },
  {
    path: "device/privacy",
    name: "DevicePrivacy",
    component: load("DevicePrivacy"),
    meta: {
      layout: "layout-empty"
    }
  },
  {
    path: "device/terms",
    name: "DeviceTerms",
    component: load("DeviceTerms"),
    meta: {
      layout: "layout-empty"
    }
  },
  {
    path: "*",
    name: "404",
    component: load("404"),
    alias: "/404",
    meta: {
      layout: "layout-default"
    }
  }
];
