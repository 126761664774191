<template>
  <div>
    <div class="main-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutEmpty",
  mounted() {
    document.body.classList.add("layout-empty");
  },
  destroyed() {
    document.body.classList.remove("layout-empty");
  }
};
</script>
