var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"footer-details"},[_c('b-link',{staticClass:"footer-logo",attrs:{"to":((_vm.locale.base) + "/")}},[_c('img',{attrs:{"src":(_vm.publicPath + "assets/images/logo-footer.png"),"alt":"TREBEL"}})]),_c('div',{staticClass:"footer-connect-with-us"},[_vm._v(" "+_vm._s(_vm.$t("connectWithUs"))+" ")]),_c('ul',{staticClass:"footer-socials"},[_c('li',[_c('a',{attrs:{"href":_vm.$i18n.locale === 'us'
                    ? 'https://www.instagram.com/trebelmusic/'
                    : 'https://www.instagram.com/trebelmx/',"target":"_blank"}},[_c('img',{attrs:{"src":(_vm.publicPath + "assets/images/social-instagram.svg"),"alt":"Instagram"}})])]),_c('li',[_c('a',{attrs:{"href":_vm.$i18n.locale === 'us'
                    ? 'https://www.facebook.com/trebelapp'
                    : 'https://www.facebook.com/trebelmx/',"target":"_blank"}},[_c('img',{attrs:{"src":(_vm.publicPath + "assets/images/social-facebook.svg"),"alt":"Facebook"}})])]),_c('li',[_c('a',{attrs:{"href":_vm.$i18n.locale === 'us'
                    ? 'https://twitter.com/trebelmusic'
                    : 'https://twitter.com/trebelmusic',"target":"_blank"}},[_c('img',{attrs:{"src":(_vm.publicPath + "assets/images/social-twitter.svg"),"alt":"Twitter"}})])]),_c('li',[_c('a',{attrs:{"href":_vm.$i18n.locale === 'us'
                    ? 'https://www.youtube.com/channel/UCPy1kySujznHGx2wHXwUWTg'
                    : 'https://www.youtube.com/channel/UClmDcKB8ekgS9IbNJIUWETQ',"target":"_blank"}},[_c('img',{attrs:{"src":(_vm.publicPath + "assets/images/social-youtube.svg"),"alt":"YouTube"}})])])])],1)]),_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"footer-menu-wrap"},[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"footer-label"},[_vm._v("TREBEL")]),_c('ul',{staticClass:"footer-nav"},[_c('li',[_c('b-link',{attrs:{"to":_vm.$i18n.locale === 'us'
                        ? ((_vm.locale.base) + "/free")
                        : ((_vm.locale.base) + "/gratis")}},[_vm._v(_vm._s(_vm.$t("download")))])],1),_c('li',[_c('b-link',{attrs:{"to":((_vm.locale.base) + "/brands")}},[_vm._v(_vm._s(_vm.$t("brands")))])],1)])]),_c('b-col',[_c('h3',{staticClass:"footer-label"},[_vm._v(_vm._s(_vm.$t("aboutUs")))]),_c('ul',{staticClass:"footer-nav"},[_c('li',[_c('b-link',{attrs:{"to":((_vm.locale.base) + "/our-story#about")}},[_vm._v(_vm._s(_vm.$t("ourStory")))])],1),_c('li',[_c('b-link',{attrs:{"to":((_vm.locale.base) + "/our-story#leadership")}},[_vm._v(_vm._s(_vm.$t("leadership")))])],1),_c('li',[_c('b-link',{attrs:{"to":((_vm.locale.base) + "/our-story#media-mentions")}},[_vm._v(_vm._s(_vm.$t("mediaMentions")))])],1),_c('li',[_c('b-link',{attrs:{"to":((_vm.locale.base) + "/our-story#contact-us")}},[_vm._v(_vm._s(_vm.$t("contactUs")))])],1)])]),_c('b-col',[_c('h3',{staticClass:"footer-label"},[_vm._v(_vm._s(_vm.$t("legal")))]),_c('ul',{staticClass:"footer-nav"},[_c('li',[_c('b-link',{attrs:{"to":((_vm.locale.base) + "/privacy")}},[_vm._v(_vm._s(_vm.$t("privacyPolicy")))])],1),_c('li',[_c('b-link',{attrs:{"to":((_vm.locale.base) + "/terms")}},[_vm._v(_vm._s(_vm.$t("terms")))])],1)])])],1)],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }