import Vue from "vue";
import {
  isBrowser,
  isMobile,
  isIOS,
  isAndroid,
  deviceDetect
} from "mobile-device-detect";

Vue.mixin({
  computed: {
    _isBrowser() {
      return isBrowser;
    },
    _isMobile() {
      return isMobile;
    },
    _isIOS() {
      return isIOS;
    },
    _isAndroid() {
      return isAndroid;
    },
    _isHuawei() {
      let device = deviceDetect();
      return (
        device.os.toLocaleLowerCase() === "android" &&
        ((device.vendor && device.vendor.toLocaleLowerCase() === "huawei") ||
          (device.ua && device.ua.toLocaleLowerCase().indexOf("huawei") > -1))
      );
    }
  }
});
