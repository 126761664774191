<template>
  <div>
    <header class="header">
      <b-navbar toggleable="lg">
        <div class="container">
          <b-navbar-brand :to="`${locale.base}/`"
            ><img :src="`${publicPath}assets/images/logo.png`" alt="TREBEL"
          /></b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <template v-slot:default="{ expanded }">
              <img
                v-if="expanded"
                :src="`${publicPath}assets/images/mobile-hamburguer-close.png`"
                alt="TREBEL"
                @click="hideLogo = false"
              />
              <img
                v-else
                :src="`${publicPath}assets/images/mobile-hamburguer.png`"
                alt="TREBEL"
                @click="hideLogo = true"
              />
            </template>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mobile-language-nav">
              <b-nav-item
                :href="languagePath('')"
                :active="$i18n.locale === 'us'"
                >ENGLISH</b-nav-item
              >
              <b-nav-item
                :href="languagePath('mx')"
                :active="$i18n.locale === 'mx'"
                >ESPAÑOL</b-nav-item
              >
            </b-navbar-nav>

            <b-navbar-nav
              order-lg="1"
              order-sm="2"
              right
              class="main-nav ml-auto "
            >
              <b-nav-item
                :to="
                  $i18n.locale === 'us'
                    ? `${locale.base}/free`
                    : `${locale.base}/gratis`
                "
                active-class="active"
                >{{ $t("download") }}</b-nav-item
              >
              <b-nav-item :to="`${locale.base}/brands`" active-class="active">{{
                $t("brands")
              }}</b-nav-item>
              <b-nav-item
                :to="`${locale.base}/our-story`"
                active-class="active"
                >{{ $t("ourStory") }}</b-nav-item
              >
              <b-nav-item
                v-if="$i18n.locale === 'mx'"
                href="https://support.trebel.io/"
                target="_blank"
                active-class="active"
                >{{ $t("help") }}</b-nav-item
              >
              <b-nav-item
                @click="openModal"
                active-class="active"
                class="nav-item-contact-us"
                >{{ $t("contactUs") }}</b-nav-item
              >
            </b-navbar-nav>

            <b-navbar-nav class="language-nav">
              <b-nav-item
                :href="languagePath('')"
                :active="$i18n.locale === 'us'"
                >En</b-nav-item
              >
              <b-nav-text><div class="separator"></div></b-nav-text>
              <b-nav-item
                :href="languagePath('mx')"
                :active="$i18n.locale === 'mx'"
                >Es</b-nav-item
              >
            </b-navbar-nav>
          </b-collapse>
          <b-link class="header-contact" @click="openModal">
            <img :src="`${publicPath}assets/images/icon-email.svg`" alt="" />
          </b-link>
        </div>
      </b-navbar>
    </header>
    <ModalContact></ModalContact>
  </div>
</template>

<script>
import store from "@/store";
import ModalContact from "@/components/Modal/ModalContact.vue";

export default {
  components: {
    ModalContact
  },
  computed: {
    locale() {
      return store.getters.locale;
    },
    currentRoute() {
      return this.$router.resolve({
        name: this.$route.name
      });
    }
  },
  data() {
    return {
      hideLogo: false
    };
  },
  methods: {
    openModal() {
      this.$eventHub.$emit("modalContactOpen");
    },
    languagePath(lang) {
      let current = this.currentRoute.route.path;
      let baseUrl = process.env.VUE_APP_BASE_URL;

      if ((current === "/" || current === "/") && lang === "") {
        baseUrl = "";
      }

      if (lang === "mx" && this.currentRoute.route.name === "Download") {
        current = "/gratis";
      }

      let url = `${baseUrl}/${lang}${current}`;

      url = url.replace(/\/\//g, "/");

      if (url.length > 1) {
        url = url.replace(/\/$/, "");
      }

      return url;
    }
  }
};
</script>
