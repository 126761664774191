<template>
  <div id="app">
    <component v-if="layout" :is="layout">
      <transition name="fade">
        <loader v-if="$root.loader"></loader>
        <router-view v-else></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    }
  },
  watch: {
    $route(to) {
      document.title =
        to.meta.title ||
        "TREBEL MUSIC: Free Music Download App for Android and iPhone";
    }
  }
};
</script>

<style></style>
