<template>
  <div>
    <Header></Header>
    <div class="main-content">
      <slot />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "LayoutDefault",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    document.body.classList.add("layout-default");
  },
  destroyed() {
    document.body.classList.remove("layout-default");
  }
};
</script>
