import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import i18n from "../plugins/i18n";
import axios from "axios";

import routes from "./routes";

import { localize } from "vee-validate";

import { SUPPORTED_LOCALES } from "../constants/locale";

Vue.use(VueRouter);

function getLocaleRegex() {
  let reg = "";
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${
      index !== SUPPORTED_LOCALES.length - 1 ? "|" : ""
    }`;
  });
  return `(${reg})`;
}

function getLocale(locale = "us") {
  return SUPPORTED_LOCALES.find(loc => loc.code === locale);
}

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: `/:locale${getLocaleRegex()}?`,
      component: {
        template: "<router-view></router-view>"
      },
      beforeEnter(to, from, next) {
        let uniq = new Date().getTime();
        const locale = getLocale(to.params.locale);
        store.dispatch("setLocale", locale);
        axios
          .get(`${locale.translations}?v=${uniq}`)
          .then(res => {
            i18n.setLocaleMessage(locale.code, res.data || {});
          })
          .catch(() => {
            // TODO handle error
          })
          .finally(() => {
            localize(locale.code);
            i18n.locale = locale.code;
            next();
          });
      },
      children: routes
    }
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        x: 0,
        y: document.getElementById(to.hash.substr(1)).offsetTop - 86
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
