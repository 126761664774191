<template>
  <footer class="footer">
    <div class="container">
      <b-row>
        <b-col md="4">
          <div class="footer-details">
            <b-link class="footer-logo" :to="`${locale.base}/`">
              <img
                :src="`${publicPath}assets/images/logo-footer.png`"
                alt="TREBEL"
              />
            </b-link>
            <div class="footer-connect-with-us">
              {{ $t("connectWithUs") }}
            </div>
            <ul class="footer-socials">
              <li>
                <a
                  :href="
                    $i18n.locale === 'us'
                      ? 'https://www.instagram.com/trebelmusic/'
                      : 'https://www.instagram.com/trebelmx/'
                  "
                  target="_blank"
                >
                  <img
                    :src="`${publicPath}assets/images/social-instagram.svg`"
                    alt="Instagram"
                  />
                </a>
              </li>
              <li>
                <a
                  :href="
                    $i18n.locale === 'us'
                      ? 'https://www.facebook.com/trebelapp'
                      : 'https://www.facebook.com/trebelmx/'
                  "
                  target="_blank"
                >
                  <img
                    :src="`${publicPath}assets/images/social-facebook.svg`"
                    alt="Facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  :href="
                    $i18n.locale === 'us'
                      ? 'https://twitter.com/trebelmusic'
                      : 'https://twitter.com/trebelmusic'
                  "
                  target="_blank"
                >
                  <img
                    :src="`${publicPath}assets/images/social-twitter.svg`"
                    alt="Twitter"
                  />
                </a>
              </li>
              <li>
                <a
                  :href="
                    $i18n.locale === 'us'
                      ? 'https://www.youtube.com/channel/UCPy1kySujznHGx2wHXwUWTg'
                      : 'https://www.youtube.com/channel/UClmDcKB8ekgS9IbNJIUWETQ'
                  "
                  target="_blank"
                >
                  <img
                    :src="`${publicPath}assets/images/social-youtube.svg`"
                    alt="YouTube"
                  />
                </a>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col md="8">
          <div class="footer-menu-wrap">
            <b-row>
              <b-col>
                <h3 class="footer-label">TREBEL</h3>
                <ul class="footer-nav">
                  <li>
                    <b-link
                      :to="
                        $i18n.locale === 'us'
                          ? `${locale.base}/free`
                          : `${locale.base}/gratis`
                      "
                      >{{ $t("download") }}</b-link
                    >
                  </li>
                  <li>
                    <b-link :to="`${locale.base}/brands`">{{
                      $t("brands")
                    }}</b-link>
                  </li>
                </ul>
              </b-col>
              <b-col>
                <h3 class="footer-label">{{ $t("aboutUs") }}</h3>
                <ul class="footer-nav">
                  <li>
                    <b-link :to="`${locale.base}/our-story#about`">{{
                      $t("ourStory")
                    }}</b-link>
                  </li>
                  <li>
                    <b-link :to="`${locale.base}/our-story#leadership`">{{
                      $t("leadership")
                    }}</b-link>
                  </li>
                  <li>
                    <b-link :to="`${locale.base}/our-story#media-mentions`">{{
                      $t("mediaMentions")
                    }}</b-link>
                  </li>
                  <li>
                    <b-link :to="`${locale.base}/our-story#contact-us`">{{
                      $t("contactUs")
                    }}</b-link>
                  </li>
                </ul>
              </b-col>
              <b-col>
                <h3 class="footer-label">{{ $t("legal") }}</h3>
                <ul class="footer-nav">
                  <li>
                    <b-link :to="`${locale.base}/privacy`">{{
                      $t("privacyPolicy")
                    }}</b-link>
                  </li>
                  <li>
                    <b-link :to="`${locale.base}/terms`">{{
                      $t("terms")
                    }}</b-link>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
import store from "@/store";
export default {
  computed: {
    locale() {
      return store.getters.locale;
    }
  }
};
</script>
