import Vue from "vue";

Vue.mixin({
  methods: {
    generateUrl(locale) {
      let currentRoute = this.$router.resolve({
        name: this.$route.name
      });

      let lang = locale.base;
      let currentPath = currentRoute.route.path;

      let url = `${process.env.VUE_APP_BASE_URL}${lang}${currentPath}`;

      if (lang === "" && currentPath === "/") {
        url = "";
      }

      return url;
    },
    generateMeta(params) {
      return [
        {
          name: "description",
          content: params.description
        },
        { rel: "canonical", href: `http://trebel.io${params.url}` },
        {
          property: "al:ios:url",
          content: "trebel://"
        },
        {
          name: "apple-mobile-web-app-capable",
          content: "yes"
        },
        {
          property: "al:ios:app_store_id",
          content: "912390687"
        },
        {
          property: "al:ios:app_name",
          content: "TREBEL Music - Song Downloader"
        },
        {
          property: "al:android:url",
          content: "trebel://"
        },
        {
          property: "al:android:app_name",
          content: "TREBEL - Free Music Downloads"
        },
        {
          property: "al:android:package",
          content: "com.mmm.trebelmusic"
        },
        {
          property: "fb:app_id",
          content: "614494898692745"
        },
        {
          property: "og:site_name",
          content: "TREBEL - Free Music Downloads"
        },
        { property: "og:url", content: `http://trebel.io${params.url}` },
        {
          property: "og:title",
          content: params.title
        },
        {
          property: "og:description",
          content: params.description
        },
        { property: "og:type", content: "website" },
        {
          property: "og:image",
          content: "https://trebel.io/web/assets/images/trebel-icon.png"
        }
      ];
    }
  }
});
