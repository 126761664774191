export const SUPPORTED_LOCALES = [
  {
    code: "us",
    base: "",
    name: "English",
    translations: `${process.env.BASE_URL}translations/us.json`
  },
  {
    code: "mx",
    base: "/mx",
    name: "Spanish",
    translations: `${process.env.BASE_URL}translations/mx.json`
  }
];
